<template>
  <router-view v-slot="{ Component, route }">
    <transition name="forward">
      <keep-alive :max="10" v-if="route.meta.keepAlive">
        <component :key="route.fullPath" :is="Component" />
      </keep-alive>
      <component :key="route.fullPath" :is="Component" v-else />
    </transition>
  </router-view>
</template>

<script>
import { useStore } from "vuex";
import { reactive, toRefs } from "vue";
export default {
  setup() {
    const store = useStore();
    const state = reactive({
      transitionName: store.state.common.transitionName,
    });
    return { ...toRefs(state) };
  },
  watch: {
    $route(to) {
      console.log(to.meta.keepAlive);
    },
  },
};
</script>

<style>
</style>