// src/router/routes.js
const list = () => import('@/view/index.vue');
const calendarlist = () => import('@/view/calendar/index.vue');
const calendarmanager = () => import('@/view/calendar/manger.vue');

const routes = [
    { path: '/', redirect: '/list' },
    {
        path: '/list',
        name: 'list',
        component: list,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/calendarmanager',
        name: 'calendarmanager',
        component: calendarmanager,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/calendarlist',
        name: 'calendarlist',
        component: calendarlist,
        meta: {
            keepAlive: true
        }
    }
];
export default routes;