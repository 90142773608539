import { createStore } from 'vuex'
import common from './modules/common'
import getters from './getters'

export default createStore({
  modules: {
    common
  },
  getters
})
