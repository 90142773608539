import Cookies from 'js-cookie'
export const DateTools = {
    dayMessage: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    monthMessage: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    mOneDay: 1000 * 60 * 60 * 24,
    //定义一些常用的日期格式的常量   
    DEFAULT_DATE_FORMAT: 'yyyy-MM-dd',
    DEFAULT_MONTH_FORMAT: 'yyyy-MM',
    DEFAULT_YEAR_FORMAT: 'yyyy',
    DEFAULT_TIME_FORMAT: 'HH:mm:ss',
    DEFAULT_DATETIME_FORMAT: 'yyyy-MM-dd HH:mm:ss',
    DEFAULT_YEAR: 'YEAR',
    DEFAULT_MONTH: 'MONTH',
    DEFAULT_DATE: 'DATE',
    DEFAULT_HOUR: 'HOUR',
    DEFAULT_MINUTE: 'MINUTE',
    DEFAULT_SECOND: 'SECOND',
    formatDatetime(strFormat, date) {
        try {
            if (date === undefined) {
                this.curDate = new Date();
            } else if (!(date instanceof Date)) {
                this.debug("你输入的date:" + date + "不是日期类型");
                return date;
            } else {
                this.curDate = date;
            }
            return this.formatCurrentDate(strFormat);
        } catch (e) {
            console.log("格式化日期出现异常：" + e.message);
        }
    },
    formatCurrentDate(strFormat) {
        try {
            var tempFormat =
                strFormat === undefined ? this.DEFAULT_DATETIME_FORMAT : strFormat;
            var dates = this.getDateObject(this.curDate);
            if (/(y+)/.test(tempFormat)) {
                var fullYear = this.curDate.getFullYear() + "";
                var year =
                    RegExp.$1.length === 4
                        ? fullYear
                        : fullYear.substr(4 - RegExp.$1.length);
                tempFormat = tempFormat.replace(RegExp.$1, year);
            }
            for (var i in dates) {
                if (new RegExp("(" + i + ")").test(tempFormat)) {
                    var target =
                        RegExp.$1.length === 1
                            ? dates[i]
                            : ("0" + dates[i]).substr(("" + dates[i]).length - 1);
                    tempFormat = tempFormat.replace(RegExp.$1, target);
                }
            }
            return tempFormat === strFormat
                ? this.curDate.toLocaleString()
                : tempFormat;
        } catch (e) {
            console.log("格式化日期出现异常：" + e.message);
        }
    },
    getDateObject(date) {
        if (!(date instanceof Date)) {
            this.curDate = new Date();
            date = this.curDate;
        }
        return {
            "M+": date.getMonth() + 1,
            "d+": date.getDate(),
            "H+": date.getHours(),
            "m+": date.getMinutes(),
            "s+": date.getSeconds()
        };
    },
    getTimeAgo(dateTimeStamp) {
        var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
        var hour = minute * 60;
        var day = hour * 24;
        var week = day * 7;
        var halfamonth = day * 15;
        var month = day * 30;
        var now = new Date().getTime();   //获取当前时间毫秒
        console.log(now)
        var diffValue = now - dateTimeStamp;//时间差

        if (diffValue < 0) {
            return;
        }
        var minC = diffValue / minute;  //计算时间差的分，时，天，周，月
        var hourC = diffValue / hour;
        var dayC = diffValue / day;
        var weekC = diffValue / week;
        var monthC = diffValue / month;
        var result = "";
        if (monthC >= 1 && monthC <= 6) {
            result = " " + parseInt(monthC) + "个月前"
        } else if (weekC >= 1 && weekC < 5) {
            result = " " + parseInt(weekC) + "周前"
        } else if (dayC >= 1 && dayC <= 6) {
            result = " " + parseInt(dayC) + "天前"
        } else if (hourC >= 1 && hourC <= 23) {
            result = " " + parseInt(hourC) + "个小时前"
        } else if (minC >= 1 && minC <= 59) {
            result = " " + parseInt(minC) + "分钟前"
        } else if (diffValue >= 0 && diffValue <= minute) {
            result = "刚刚"
        } else {
            var datetime = new Date();
            datetime.setTime(dateTimeStamp);
            var Nyear = datetime.getFullYear();
            var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
            var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
            var Nhour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
            var Nminute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
            var Nsecond = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
            result = Nyear + "-" + Nmonth + "-" + Ndate
        }
        return result;
    },
    /** 
* 根据给定的时间间隔类型及间隔值，以给定的格式对给定的时间进行计算并格式化返回 
* @params date 要操作的日期时间可以为时间的字符串或者{@see Date}类似的时间对象， 
* @params interval 时间间隔类型如："YEAR"、"MONTH"、 "DATE", 不区分大小写 
* @params amount 时间间隔值，可以正数和负数, 负数为在date的日期减去相应的数值，正数为在date的日期上加上相应的数值 
* @params strFormat 当输入端的date的格式为字符串是，此项必须输入。若date参数为{@see Date}类型是此项会作为最终输出的格式。 
* @params targetFormat 最终输出的日期时间的格式，若没有输入则使用strFormat或者默认格式'yyyy-MM-dd HH:mm:ss' 
* @return 返回计算并格式化后的时间的字符串 
*/
    changeDate: function (date, interval, amount, strFormat, targetFormat) {
        var tmpdate = new Date();
        if (date === undefined) {
            this.debug('输入的时间不能为空!');
            return new Date();
        } else if (typeof date === 'string') {
            tmpdate = this.parseDate(date, strFormat);
        } else if (date instanceof Date) {
            tmpdate = date;
        }
        var field = (typeof interval === 'string') ? interval.toUpperCase() : 'DATE';
        try {
            amount = parseInt(amount + '', 10);
            if (isNaN(amount)) {
                amount = 0;
            }
        } catch (e) {
            this.debug('你输入的[amount=' + amount + ']不能转换为整数');
            amount = 0;
        }
        switch (field) {
            case this.DEFAULT_YEAR:
                tmpdate.setFullYear(tmpdate.getFullYear() + amount);
                break;
            case this.DEFAULT_MONTH:
                tmpdate.setMonth(tmpdate.getMonth() + amount);
                break;
            case this.DEFAULT_DATE:
                tmpdate.setDate(tmpdate.getDate() + amount);
                break;
            case this.DEFAULT_HOUR:
                tmpdate.setHours(tmpdate.getHours() + amount);
                break;
            case this.DEFAULT_MINUTE:
                tmpdate.setMinutes(tmpdate.getMinutes() + amount);
                break;
            case this.DEFAULT_SECOND:
                tmpdate.setSeconds(tmpdate.getSeconds() + amount);
                break;
            default:
                this.debug('你输入的[interval:' + field + '] 不符合条件!');
        }

        this.curDate = tmpdate;
        return this.formatCurrentDate(targetFormat === undefined ? strFormat : targetFormat);
    },
    getDateByDay(number, date) {
        if (!date) {
            date = new Date();
        }
        return new Date(date.getTime() + parseInt(1000 * 60 * 60 * 24 * number));
    },
}
export const ComTools = {
    //判断是否是空字符串
    isEmptyString(data) {
        if (typeof data === "undefined")
            return true;
        if (data === "")
            return true;
        if (data === null)
            return true;
        if (data === "null")
            return true;
        return false;
    },
    //判断是否是空对象
    isNullObject(obj) {
        if (typeof obj === "undefined") {
            return true;
        }
        if (obj === null) {
            return true;
        }
        return false;
    },
    //深度复制
    assertOfDepth(source) {
        var str, newobj = source.constructor === Array ? [] : {};
        if (typeof source !== 'object') {
            return;
        } else if (window.JSON) {
            str = JSON.stringify(source), //系列化对象
                newobj = JSON.parse(str); //还原
        } else {

            for (var i in source) {
                newobj[i] = typeof source[i] === 'object' ? cloneObj(source[i]) : source[i];
            }
        }
        return newobj;
    },
    /**
 *
 * @param {*} tree //树组件
 * @param {*} node //选中的node节点
 * @param {*} expand //是否展开
 */
    treeClickChecked(tree, node) {
        var store = tree.store
        store.setCurrentNode(node)
        tree.$emit('current-change', store.currentNode ? store.currentNode.data : null, store.currentNode)
        tree.$emit('node-click', node.data, node, tree)
    },
    //获取IE版本号
    IEVersion() {
        var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
        var isIE =
            userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
        var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
        var isIE11 =
            userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1;
        if (isIE) {
            var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
            reIE.test(userAgent);
            var fIEVersion = parseFloat(RegExp["$1"]);
            if (fIEVersion == 7) {
                return 7;
            } else if (fIEVersion == 8) {
                return 8;
            } else if (fIEVersion == 9) {
                return 9;
            } else if (fIEVersion == 10) {
                return 10;
            } else {
                return 6; //IE版本<=7
            }
        } else if (isEdge) {
            return "edge"; //edge
        } else if (isIE11) {
            return 11; //IE11
        } else {
            return -1; //不是ie浏览器
        }
    },
    //下载文件兼容ie
    openDownloadDialog(url, saveName) {
        url = "data:application/vnd.ms-excel;base64," + url;
        // for ie 10 and later
        let obj = this.convertBase64UrlToBlob(url);
        if (window.navigator.msSaveOrOpenBlob) {
            try {
                window.navigator.msSaveOrOpenBlob(obj, saveName); // The user only has the option of clicking the Save button.
            } catch (e) {
                console.log(e);
            }
        } else {
            if (typeof url == "object" && url instanceof Blob) {
                url = URL.createObjectURL(url); // 创建blob地址
            }
            let tmpa = document.createElement("a");
            tmpa.download = saveName || "下载";
            tmpa.href = URL.createObjectURL(obj); //绑定a标签
            //tmpa.click(); //模拟点击实现下载
            tmpa.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(function () {
                //延时释放
                URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
            }, 100);
        }
    },

    convertBase64UrlToBlob(base64) {
        var parts = base64.split(";base64,");
        var raw = this.base64decode(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; i++) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: "application/octet-stream" });
    },
    base64decode(str) {
        var base64DecodeChars = new Array(-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1);
        var c1, c2, c3, c4;
        var i, len, out;
        len = str.length;
        i = 0;
        out = "";
        while (i < len) {
            /* c1 */
            do {
                c1 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
            }
            while (i < len && c1 == -1);
            if (c1 == -1)
                break;
            /* c2 */
            do {
                c2 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
            }
            while (i < len && c2 == -1);
            if (c2 == -1)
                break;
            out += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4));
            /* c3 */
            do {
                c3 = str.charCodeAt(i++) & 0xff;
                if (c3 == 61)
                    return out;
                c3 = base64DecodeChars[c3];
            }
            while (i < len && c3 == -1);
            if (c3 == -1)
                break;
            out += String.fromCharCode(((c2 & 0XF) << 4) | ((c3 & 0x3C) >> 2));
            /* c4 */
            do {
                c4 = str.charCodeAt(i++) & 0xff;
                if (c4 == 61)
                    return out;
                c4 = base64DecodeChars[c4];
            }
            while (i < len && c4 == -1);
            if (c4 == -1)
                break;
            out += String.fromCharCode(((c3 & 0x03) << 6) | c4);
        }
        return out;
    },
    /**
 * 格式化含参数的字符串，参数为%1，%2...，示例如下：<br />
 * <pre>
 * var sTmp = "wellcome to %1,today is %2";
 * sTmp = ComTools.format(sTmp,'meilanhu','monday');
 *
 * </pre>
 * @param {type} text 第一个是格式化的字符串，后面的是参数用','分隔
 * @returns {unresolved} 格式化好的字符串
 */
    format(text) {
        var args = arguments;
        var re = new RegExp("%([1-" + args.length + "])", "g");
        return String(text).replace(re, function ($1, $2) {
            return args[$2];
        });
    },
    //将表情转换为编码
    emojiToStr(html) {
        var sFace = "[face:%1]";
        html = html.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, name) {
            var iStart = name.lastIndexOf("_");
            var iEnd = name.lastIndexOf(".");
            var index = name.substring(iStart + 1, iEnd);
            var str = ComTools.format(sFace, index);
            return str;
        });
        html = html.replace(/\"/g, "\'");
        return html;
    },
    //将编码转为表情图片
    strToEmoji(str, faceList) {
        var reg = /\[.+?\]/g;
        if (!ComTools.isEmptyString(str)) {
            str = str.replace(reg, function (a, b) {
                var sValue = faceList[a];
                if (!ComTools.isEmptyString(sValue)) {
                    return sValue;
                } else {
                    return a;
                }
            });
        }
        return str;
    },
    //根据名字生成头像
    letterAvatar(name, size, color) {
        name = name || '';
        size = size || 60;
        var colours = [
            "#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50",
            "#f1c40f", "#e67e22", "#e74c3c", "#00bcd4", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"
        ],
            nameSplit = String(name).split(' '),
            initials, charIndex, colourIndex, canvas, context, dataURI;
        if (nameSplit.length == 1) {
            initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?';
        } else {
            initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
        }

        charIndex = (initials == '?' ? 72 : initials.charCodeAt(0)) - 64;
        colourIndex = charIndex % 20;
        canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        context = canvas.getContext("2d");

        context.fillStyle = color ? color : colours[colourIndex - 1];
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = Math.round(canvas.width / 2) + "px 'Microsoft Yahei'";
        context.textAlign = "center";
        context.fillStyle = "#FFF";
        context.fillText(initials, size / 2, size / 1.5);
        dataURI = canvas.toDataURL();
        canvas = null;
        return dataURI;
    },
    buildArrayKey(array, fieldName) {
        let aEntities = { Rows: array };
        for (var i = 0; i < aEntities.Rows.length; i++) {
            var row = aEntities.Rows[i];
            var sName = row[fieldName];
            aEntities[sName] = row;
        }
        return aEntities;
    },
    /**将数组转换为树
    *data 数组
    *id数据标识Id
    *pid数据标识父Id
    *name转为title的原始字段
    */
    arrayToTree(data, id, pid, name) {
        if (!data || !data.length)
            return [];
        var targetData = []; //存储数据的容器(返回)
        var records = {};
        var itemLength = data.length; //数据集合的个数
        for (var i = 0; i < itemLength; i++) {
            var o = data[i];
            var key = getKey(o[id]);
            records[key] = o;
        }
        for (var i = 0; i < itemLength; i++) {
            var currentData = data[i];
            currentData["title"] = currentData[name];
            var key = getKey(currentData[pid]);
            var parentData = records[key];
            if (!parentData) {
                targetData.push(currentData);
                continue;
            }
            parentData["children"] = parentData["children"] || [];
            parentData["children"].push(currentData);
        }
        return targetData;
        function getKey(key) {
            if (typeof (key) === "string")
                key = key.replace(/[.]/g, '').toLowerCase();
            return key;
        }
    },


    /* 判断数组对象属性值是否重复*/
    isRepeat(arr) {
        var hash = {};
        for (var i in arr) {
            if (hash[arr[i]]) {
                return true;
            }
            hash[arr[i]] = true;
        }
        return false;
    },

    // 数组去重
    unique(arr) {
        if (!Array.isArray(arr)) {
            console.log('type error!')
            return
        }
        var array = [];
        for (var i = 0; i < arr.length; i++) {
            if (array.indexOf(arr[i]) === -1) {
                array.push(arr[i])
            }
        }
        return array;
    },
    getBaseUrl() {
        return process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? process.env.BASE_URL : window.SITE_CONFIG.baseUrl;
    },
    isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/micromessenger/i) !== null) {
            return true;
        } else {
            return false;
        }
    },
    getQueryString(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    },
    setCookies(value) {
        Cookies.set('userInfo', JSON.stringify(value), { expires: 30 });
    },
    getCookies() {
        let sUser = Cookies.get('userInfo');
        if (this.isEmptyString(sUser)) {
            return "";
        } else {
            return JSON.parse(sUser);
        }
    },
    setToken(value) {
        Cookies.set('token', value, { expires: 1 });
    },
    getToken() {
        return Cookies.get('token');
    },
    delToken() {
        Cookies.remove('token');
    },
    setParams(value) {
        localStorage.setItem('params', JSON.stringify(value));
    },
    getParams() {
        let sParams = localStorage.getItem("params");
        if (!this.isEmptyString(sParams)) {
            return JSON.parse(sParams);
        } else {
            return {};
        }
    },
    setQueryParams(to) {
        let sCategoryId = to.query.categoryId || "";
        let sBelongerId = to.query.belongerId || "";
        let sProductId = to.query.productId || "";
        let oParams = {};
        if (!ComTools.isEmptyString(sCategoryId)) {
            oParams.categoryId = sCategoryId;
        }
        if (!ComTools.isEmptyString(sBelongerId)) {
            oParams.belongerId = sBelongerId;
        }
        if (!ComTools.isEmptyString(sProductId)) {
            oParams.productId = sProductId;
        }
        ComTools.setParams(oParams);
    },
    wxShare(ticket, link, imgUrl, title, desc) {
        var aJsApiList = ['onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQZone'];
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: ticket.appId, // 公众号的唯一标识
            timestamp: ticket.timestamp, // 生成签名的时间戳
            nonceStr: ticket.nonceStr, // 生成签名的随机串
            signature: ticket.signature, // 签名，
            jsApiList: aJsApiList // 需要使用的JS接口列表
        });
        wx.ready(function () {
            wx.error(function (res) {
                console.log(res);
                // config信息验证失败会执行fail函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。

            });
            wx.onMenuShareTimeline({
                title: title, // 分享标题
                link: link, // 分享链接
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 用户确认分享后执行的回调函数

                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });
            wx.onMenuShareAppMessage({
                title: title, // 分享标题
                desc: desc || '', // 分享描述
                link: link, // 分享链接
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 用户确认分享后执行的回调函数

                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });
            wx.onMenuShareQZone({
                title: title, // 分享标题
                desc: desc || '', // 分享描述
                link: link, // 分享链接
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 用户确认分享后执行的回调函数

                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });
        });
    },
    onBridgeReady(paymentMap, callback) {
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
            "appId": paymentMap.appId,
            "timeStamp": paymentMap.timeStamp,
            "nonceStr": paymentMap.nonceStr,
            "package": paymentMap.package,
            "signType": paymentMap.signType,
            "paySign": paymentMap.paySign
        }, function (res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
                callback(1);
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                callback(2);
            } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                callback(3);
            }
        });
    }

}
