import request from '@/utils/request'

export function authorize(params) {
    return request({
        url: '/login/findTokenByCode',
        method: 'post',
        params
    })
}
export function findUserList() {
    return request({
        url: '/user/findUserIdByCode',
        method: 'post'
    })
}

export function findSpNoList(params) {
    return request({
        url: '/weiXin/findSpNoList',
        method: 'post',
        params
    })
}

export function findTimeByName(params) {
    return request({
        url: '/weiXin/findTimeByName',
        method: 'post',
        params
    })
}

export function findDept(params) {
    return request({
        url: '/dept/findDept',
        method: 'post'
    })
}

export function findSpNoListsPage(params) {
    return request({
        url: '/weiXin/findSpNoListsPage',
        method: 'post',
        params
    })
}

export function getJbTxData(params) {
    return request({
        url: '/weiXin/getJbTxData',
        method: 'get',
        params: params
    })
}

export function getJtJbTx(params) {
    return request({
        url: '/weiXin/getJbTxByUser',
        method: 'get',
        params: params
    })
}

export function findUserInfo(params) {
    return request({
        url: '/user/findUserInfo',
        method: 'post'
    })
}

export function getJbTxCsByYue(params) {
    return request({
        url: '/weiXin/getJbTxCsByYue',
        method: 'get',
        params: params
    })
}