const getDefaultState = () => {
    return {
        name: '',
        transitionName: "forward",
        keepAlive: false,
        routers: []
    }
}

const state = getDefaultState()

const mutations = {
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_TRANSITIONNAME: (state, transitionName) => {
        state.transitionName = transitionName
    },
    SET_KEEPALIVE: (state, keepAlive) => {
        state.keepAlive = keepAlive
    },
    SET_ROUTERS: (state, routers) => {
        state.routers = routers
    }
}

const actions = {

    openLogin({ commit }, token) {
        return new Promise((resolve, reject) => {
            commit('SET_TOKEN', token)
            setToken(token)
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

