import axios from 'axios'
import { ComTools } from './tools'
import { Notify } from "vant";
// create an axios instance
const sBaseURL = import.meta.env.VITE_APP_BASE_API;
const service = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  baseURL: sBaseURL, // api的base_url
  // baseURL: '/', // api的base_url
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  },
  timeout: 5000 * 10 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
  // Do something before request is sent
  config.headers['Authorization'] = ComTools.getToken() // 请求头带上token
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  // response => response,
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过xmlhttprequest来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  response => {
    const res = response.data
    if (res.code === 401) {
      ComTools.delToken();
      //location.reload();
      Notify(res.msg);
    }
    return response.data
  },
  error => {
    console.log('err' + error) // for debug
    Notify('网络异常，请稍后重试');
  })

export default service
