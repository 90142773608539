import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vant from 'vant';
import VueTouch from 'vue-touch'
import 'vant/lib/index.css';
import './progress' // 路由控制
import './assets/scss/index.scss'
import { ComTools, DateTools } from './utils/tools'

let app = createApp(App);
app.config.globalProperties.$ComTools = ComTools;
app.config.globalProperties.$DateTools = DateTools;
app.config.globalProperties.$notify = Vant.Notify;
app.use(Vant);
app.use(VueTouch, { name: 'v-touch' });
app.use(store);
app.use(router);
app.mount('#app');
