import router from './router'
import { ComTools } from "./utils/tools"
import { authorize } from "@/api/common"
router.beforeEach((to, from, next) => {
    if (ComTools.isWeiXin()) {
        let sToken = ComTools.getToken();
        if (ComTools.isEmptyString(sToken)) {//如果token为空说明已经过期
            //先检查授权
            var sCode = ComTools.getQueryString("wxCode");
            if (!ComTools.isEmptyString(sCode)) {//如果不为空调用接口获取用户信息
                // ComTools.setCookies({ name: "wxl" });
                // router.replace(to.path)
                authorize({ code: sCode }).then(res => {
                    ComTools.setToken(res.data);
                    //next({ ...to, replace: true });
                    let oQuery = ComTools.assertOfDepth(to.query);
                    delete oQuery.wxCode;
                    next({ path: to.path, query: oQuery, replace: true })
                })
            } else {
                let oParams = {};
                oParams.host = window.location.host;
                oParams.path = to.path;
                let oQuery = to.query || {};
                for (let key in oQuery) {
                    oParams[key] = oQuery[key]
                }
                let sParams = JSON.stringify(oParams);
                sParams = encodeURIComponent(encodeURIComponent(sParams));
                var sUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww30e74d96c7a3fd8d&redirect_uri=https://qywx.youmian.club/wxauthorizecallback.html?params=" + sParams + "&scope=snsapi_base&response_type=code&state=STATE#wechat_redirect"
                window.location.href = sUrl;
            }
        } else {
            next();
        }
    } else {
        next();
    }


})
router.afterEach((to) => {

})
